import { render, staticRenderFns } from "./rvs-root-cause.vue?vue&type=template&id=5b679330&scoped=true&"
import script from "./rvs-root-cause.vue?vue&type=script&lang=ts&"
export * from "./rvs-root-cause.vue?vue&type=script&lang=ts&"
import style0 from "./rvs-root-cause.vue?vue&type=style&index=0&id=5b679330&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b679330",
  null
  
)

export default component.exports