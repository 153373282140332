
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import RVSCalendarService from '../rvs-calendar.service';

@Component({
    components: {
        CustomGraph,
    },
})
export default class RVSRootCause extends Vue {
    @inject(KEY.RVSCalendarService)
    private rvsCalendarService!: RVSCalendarService;

    @inject(UserServiceS)
    private userService!: UserService;

    @inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    get chartData() {
        const { currentRootCause } = this.rvsCalendarService;

        if (!currentRootCause) return null;

        const data = this.rootCause.map(([, value]) => value);

        return {
            labels: this.labels,
            datasets: [{
                backgroundColor: this.colors,
                barThickness: 22,
                minBarLength: 5,
                data,
                barPercentage: 1,
            }],
        };
    }

    get rootCause() {
        const { currentRootCause } = this.rvsCalendarService;
        if (!currentRootCause) return [];

        return Object.entries(currentRootCause).sort((a, b) => b[1] - a[1]);
    }

    get labels() {
        return this.rootCause.map(([label]) => label);
    }

    get date() {
        const { year, month } = this.documentFiltersService;
        const { currentDay } = this.rvsCalendarService.storeState;

        return moment(new Date(year, month, currentDay)).format('DD.MM.YYYY');
    }

    get colors() {
        const { chartColors } = this.userService;

        return this.rootCause.map((_, index) => chartColors[index % chartColors.length]);
    }

    get options() {
        return {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
        };
    }
}
